// import { Divider } from '@mui/material';
import './Home.css'
import Menu from './Menu';
import PricingCards from './PricingCards';
import banner from './images/banner1.png'
import tradelocker from './images/tradelocker.png'
import MetaTrader5 from './images/MetaTrader-5.png'
import telegram from './images/telegram.jpeg'
import Mbanner from './images/mob-banner1.png'
import logo from '../src/images/logo.png';
import gui1 from '../src/images/GUI1.png';
import YoutubeEmbed from './YoutubeEmbed';
import { useEffect, useState } from 'react';
import SlideInSection from './SlideInSection';
import PricingTable from './PricingTable';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at a time
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000, // Auto-scroll speed in milliseconds
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
            },
        },
    ],
};

// import { Link, Element, animateScroll as scroll } from 'react-scroll';

const Home = () => {
    const [isMobile, setIsMobile] = useState(false);
    const [isBlinking, setIsBlinking] = useState(true);

    const items = [
        { src: tradelocker },
        { src: MetaTrader5 },
        { src: telegram }
        // Add more items as needed
    ];
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setIsBlinking(false);
        }, 2000); // After 3 seconds, the blinking logo will be removed

        return () => clearTimeout(timeoutId); // Cleanup if the component unmounts before the timeout finishes
    }, []);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.substring(1)); // Remove '#' from hash
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' }); // Scroll to the element smoothly
            }
        }
    }, []);
    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth <= 800); // Adjust the breakpoint as needed
        };
        checkScreenSize();

        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    return (
        <>
            {isBlinking && (
                <div className="blink-logo-container ">
                    {/* Replace with your logo */}
                    <img src={logo} className="blink-logo transition-all duration-300 animate-blink" alt="Blinking Logo" />
                </div>
            )}
            {!isBlinking && (
                <div>
                    <Menu></Menu>
                    <div id='home' className='home-body'>

                        <div>
                            {!isMobile &&
                                <img alt='banner' style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                    width: '100vw', // Set image width to 100% of viewport width
                                }} src={banner}></img>
                            }
                            {isMobile &&
                                <img alt='banner' style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                    width: '100vw', // Set image width to 100% of viewport width
                                }} src={Mbanner}></img>
                            }
                        </div>
                        <div style={{ position: 'relative', top: '-75px' }}>
                            <SlideInSection direction="up">
                                <h1 className='text-[#ff914d] text-4xl'>Trade Copiers</h1>
                            </SlideInSection>
                            <SlideInSection direction="left">
                                <h3 className='bg-[#ff914d] text-black py-1'>No need to share credentials</h3>
                            </SlideInSection>
                            <SlideInSection direction="down">
                                <h5>Just download and use as your personal bot</h5>
                            </SlideInSection>
                        </div>

                        <div>
                            <Slider {...settings} >
                                {items.map((item, index) => (
                                    <div className="item" key={index}>
                                        <img alt="banner" src={item.src} />
                                    </div>
                                ))}
                            </Slider>
                        </div>

                        <br></br>
                        <br></br>
                        <div id='' className='contact p-4 '>
                            <h2>Featured Product</h2>
                            <div className='row'>

                                <div className='col-sm-5 text-left'>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    
                                    <br></br>
                                    <br></br>
                                    <h4>TradeLocker to MT5 Copier: Effortless Trade Synchronization</h4>
                                    <br></br>
                                    <p>Streamline your trading with TradeLocker to MT5 Copier, a tool that automatically syncs your TradeLocker trades with MetaTrader 5. Whether you're opening, modifying, or closing positions, our copier mirrors your actions in real time, saving you time and reducing errors. Quick to set up and reliable, it lets you leverage MT5’s powerful features while maintaining full consistency across platforms. Simplify your trading process and trade smarter with seamless integration.</p>

                                </div>
                                <div className='col-sm-7' >
                                    <br></br>
                                    <br></br>
                                    <img alt='' src={gui1}></img>
                                </div>

                            </div>
                        </div>
                        <div id='guides' className='contact p-4 text-center'>
                            <h2>How it works?</h2>
                            <div className='row'>

                                <div className='col-sm-6'>
                                    <br></br>
                                    <br></br>
                                    <YoutubeEmbed embedId="-UGitEsucRk" />
                                </div>
                                <div className='col-sm-6' >
                                    <br></br>
                                    <br></br>
                                    <YoutubeEmbed embedId="5Qj89PZcEwo" />
                                </div>

                            </div>


                        </div>
                        <br></br>
                        <div id='products'>

                            <h2>Products</h2>
                            <SlideInSection direction="left">
                                <PricingCards></PricingCards>
                            </SlideInSection>
                        </div>

                        <div className='py-3' id='pricing'>
                            <h2>Pricing</h2>
                            <PricingTable></PricingTable>
                        </div>

                        <div className='bg-white text-black contact p-6 text-center'>
                            <h2>Payment Methods</h2>
                            <div>
                                <p>To pay via crypto wallets, please contact us through the social media links below or email us for detailed payment instructions.</p>
                                <p>Our team will guide you through the process.</p>
                            </div>

                            <div className='d-flex flex-col items-center'>
                                <div className=" bg-black d-flex text-white hover:bg-gray-500 cursor-pointer rounded-md p-5 w-[300px] m-3">
                                    <img alt='' src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Binance_logo.svg/1024px-Binance_logo.svg.png"></img>

                                </div>

                                <div className=" bg-black d-flex text-white hover:bg-gray-500 cursor-pointer rounded-md  p-5 w-[300px] m-3">
                                    <img alt='' src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Skrill_logo.svg/2560px-Skrill_logo.svg.png"></img>

                                </div>

                                <div className=" bg-black d-flex text-white hover:bg-gray-500 cursor-pointer rounded-md  p-5 w-[300px] m-3">
                                    <img alt='' src="https://companieslogo.com/img/orig/PAYO_BIG.D-cdf78d38.png?t=1639215478"></img>

                                </div>
                            </div>

                        </div>
                        <div id='contact' className='contact p-4 text-center'>
                            <h2>Contact Us</h2>
                            <p>Send me an email at <strong>support@fxdevs.com</strong> or Click on below social media links and reach out to us for customer support.</p>
                            <div className="container_ my-3 text-center">
                                <SlideInSection direction="left">
                                    <div className=' d-flex items-center flex justify-center'>
                                        <div className="pds-items-box" onClick={() => { window.open('https://discord.com/users/1006152061144023120', '_blank') }}>
                                            <img alt='' src="https://static-00.iconduck.com/assets.00/discord-icon-2048x2048-nnt62s2u.png"></img>
                                            <h6>
                                                Discord</h6>
                                        </div>
                                        <div className='text-[#ff914d] hover:text-glow m-3'>
                                            <h6>@fxdevs1</h6>
                                        </div>
                                    </div>
                                </SlideInSection>
                                <SlideInSection direction="left">
                                    <div className=' d-flex items-center flex justify-center'>
                                        <div className="pds-items-box" onClick={() => { window.open('https://t.me/fxdevs1', '_blank') }} >
                                            <img alt='' src="https://cdn3.iconfinder.com/data/icons/social-media-chamfered-corner/154/telegram-512.png"></img>
                                            <h6>
                                                Telegram</h6>
                                        </div>
                                        <div className='text-[#ff914d] hover:text-glow m-3'>
                                            <h6>@fxdevs1</h6>
                                        </div>
                                    </div>
                                </SlideInSection>
                                {/* <li>
                                        <div className="pds-items-box">
                                            <img alt='' src="https://cdn4.iconfinder.com/data/icons/miu-square-flat-social/60/whatsapp-square-social-media-512.png"></img>
                                            <h6>
                                                Whatsapp</h6>
                                        </div>
                                    </li> */}

                            </div>
                        </div>
                        <SlideInSection direction="down">
                            <div className="py-3 hfe-copyright-wrapper bg-gray-900">
                                <span>Copyright © 2024 FxDevs | Designed by FxDevs</span>
                            </div>
                        </SlideInSection>

                    </div>
                </div>

            )}
        </>
    );
}

export default Home;